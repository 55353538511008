import React from "react";
import { motion } from "framer-motion";
import {
  Clock,
  Target,
  Zap,
  DollarSign,
  CheckCircle,
  XCircle,
} from "lucide-react";

const CostComparisonSection = () => {
  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const comparisonPoints = [
    {
      title: "Kostenstruktur",
      traditional: "Stundensatzbasiert, oft unvorhersehbar",
      bottwarBytes: "Transparenter Festpreis",
      icon: DollarSign,
    },
    {
      title: "Fokus",
      traditional: "Abrechenbare Stunden maximieren",
      bottwarBytes: "Qualität und Effizienz maximieren",
      icon: Target,
    },
    {
      title: "Entwicklungsgeschwindigkeit",
      traditional: "Kann sich aufgrund von Stundenabrechnungen verzögern",
      bottwarBytes: "Schnelle Umsetzung im Fokus",
      icon: Zap,
    },
    {
      title: "Risiko",
      traditional: "Kunde trägt das Risiko von Verzögerungen",
      bottwarBytes: "Wir übernehmen das Risiko",
      icon: Clock,
    },
  ];

  return (
    <motion.section
      className="py-12 sm:py-20 bg-gradient-to-br from-gray-900 to-gray-800"
      initial="hidden"
      animate="visible"
      variants={fadeInUpVariants}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-center text-white mb-8 sm:mb-12">
          Unser Modell vs. Klassisches Agenturmodell
        </h2>
        <div className="grid gap-6 sm:gap-8">
          {comparisonPoints.map((point, index) => (
            <motion.div
              key={index}
              className="bg-gray-800 rounded-xl p-4 sm:p-6 shadow-lg"
              variants={fadeInUpVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                <point.icon className="w-6 h-6 sm:w-8 sm:h-8 text-purple-400 mr-3" />
                <h3 className="text-lg sm:text-xl font-semibold text-white">
                  {point.title}
                </h3>
              </div>
              <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-400 mb-2">
                    Klassisches Modell
                  </h4>
                  <p className="text-red-400 flex items-start">
                    <XCircle className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
                    <span>{point.traditional}</span>
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-400 mb-2">
                    BottwarBytes
                  </h4>
                  <p className="text-green-400 flex items-start">
                    <CheckCircle className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
                    <span>{point.bottwarBytes}</span>
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="mt-8 sm:mt-12 text-center"
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <p className="text-lg sm:text-xl text-gray-300 mb-6">
            Unser Modell setzt auf Qualität, Effizienz und Transparenz. Sie
            profitieren von unserer Expertise, ohne sich um versteckte Kosten
            oder Verzögerungen sorgen zu müssen.
          </p>
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-bold py-3 px-6 sm:px-8 rounded-full text-base sm:text-lg shadow-lg hover:shadow-xl transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Kostenloses Beratungsgespräch vereinbaren
          </motion.a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default CostComparisonSection;
