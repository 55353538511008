import React from "react";
import { Info } from "lucide-react";
import Navbar from "./Navbar";

const WAPrivacy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Privacy Policy
        </h1>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            <Info className="mr-2 text-blue-400" />
            WhatsAnalyze Privacy Policy
          </h2>
          <div className="text-gray-300 whitespace-pre-wrap">
            <p>
              <strong>Last updated: September 25, 2024</strong>
            </p>
            <p>
              Thank you for using WhatsAnalyze ("we," "our," or "us"). We are
              committed to protecting your personal data and ensuring your
              privacy. This privacy policy outlines how we collect, use, and
              protect your data in compliance with the General Data Protection
              Regulation (GDPR).
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              1. Data Controller
            </h3>
            <p>
              WhatsAnalyze is the data controller for the personal data
              processed through this app. You can contact us at:
              <br />- <strong>Name:</strong> Jan Christian Swoboda
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              2. Personal Data We Collect
            </h3>
            <p>
              We collect and process the following data when you use our app:
              <br />- <strong>Login Information:</strong> When you log in using
              Apple or Google, we collect your name, email address, and any
              other information provided by Apple or Google.
              <br />- <strong>WhatsApp Chat Analysis:</strong> We analyze your
              WhatsApp chats using Claude from Anthropic. However, the raw chat
              data is stored only locally on your device and is not sent to our
              servers. We only store the analyzed responses generated from the
              chat analysis.
              <br />- <strong>Firebase Services:</strong> We use Firebase
              services, which may collect data such as device information, app
              usage, and crash reports.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              3. How We Use Your Data
            </h3>
            <p>
              We use your data for the following purposes:
              <br />
              - To provide and improve our app's services.
              <br />
              - To analyze your WhatsApp chats and provide you with insights.
              <br />- To authenticate your account and manage your app
              experience.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              4. Data Storage and Retention
            </h3>
            <p>
              - <strong>WhatsApp Chat Data:</strong> Your chat data is stored
              only on your device and is not transferred or stored on our
              servers.
              <br />- <strong>Analyzed Responses:</strong> We store the analyzed
              responses on our servers. This data is retained as long as you
              have an active account or as required by applicable laws.
              <br />- <strong>Firebase Data:</strong> Data collected by Firebase
              is stored as per Firebase's data retention policies.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              5. Sharing Your Data
            </h3>
            <p>
              We do not sell, trade, or share your personal data with third
              parties, except:
              <br />
              - Claude from Anthropic: For analyzing your WhatsApp chats.
              <br />
              - Firebase: For authentication, app analytics, and other Firebase
              services.
              <br />
              We ensure that any third-party service providers comply with GDPR
              requirements.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              6. Your Rights Under GDPR
            </h3>
            <p>
              You have the following rights regarding your personal data:
              <br />- <strong>Access:</strong> You can request access to your
              personal data.
              <br />- <strong>Rectification:</strong> You can request correction
              of any inaccurate data.
              <br />- <strong>Erasure:</strong> You have the right to request
              the deletion of your data.
              <br />- <strong>Restriction:</strong> You can request the
              restriction of processing your data.
              <br />- <strong>Portability:</strong> You can request a copy of
              your data in a structured, commonly used format.
              <br />- <strong>Objection:</strong> You can object to the
              processing of your data.
              <br />
              To exercise any of these rights, please contact us at
              kontakt@bottwarbytes.de.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              7. Data Security
            </h3>
            <p>
              We implement appropriate technical and organizational measures to
              protect your personal data against unauthorized access, loss, or
              destruction.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              8. Data Transfers
            </h3>
            <p>
              If your data is transferred outside the European Economic Area
              (EEA), we ensure that appropriate safeguards are in place, such as
              standard contractual clauses or an adequacy decision by the
              European Commission.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              9. Cookies and Tracking Technologies
            </h3>
            <p>
              We may use cookies and similar tracking technologies for analytics
              and app functionality. You can manage your cookie preferences
              through your device settings.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              10. Changes to This Privacy Policy
            </h3>
            <p>
              We may update this privacy policy from time to time. Any changes
              will be posted within the app, and we encourage you to review this
              policy periodically.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              11. Contact Us
            </h3>
            <p>
              If you have any questions or concerns about this privacy policy,
              please contact us at:
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
            </p>
          </div>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. All rights reserved.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WAPrivacy;
