import React from "react";
import { Link } from "react-router-dom";
import { Eye, Database, Zap, Lock, UserCheck, RefreshCw } from "lucide-react";
import Navbar from "./Navbar";

const DatenschutzPage = () => {
  const sections = [
    {
      icon: <Eye />,
      title: "1. Einleitung und Verantwortliche Stelle",
      content:
        "BottwarBytes GbR, vertreten durch Jan Christian Swoboda, ist verantwortlich für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten gemäß Art. 4 Nr. 7 DSGVO.",
    },
    {
      icon: <Database />,
      title: "2. Erhebung und Speicherung personenbezogener Daten",
      content:
        "Wir erheben nur Daten, die Sie uns aktiv zur Verfügung stellen, wie Name, E-Mail-Adresse und Telefonnummer. Diese werden nur für die angegebenen Zwecke verwendet und nicht länger als nötig gespeichert.",
    },
    {
      icon: <Zap />,
      title: "3. Zweck der Datennutzung",
      content:
        "Ihre Daten werden zur Vertragserfüllung, zur Kommunikation mit Ihnen und zur Verbesserung unserer Dienste verwendet. Eine Weitergabe an Dritte erfolgt nur, wenn dies gesetzlich erforderlich ist.",
    },
    {
      icon: <Lock />,
      title: "4. Datensicherheit",
      content:
        "Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder unbefugten Zugriff zu schützen.",
    },
    {
      icon: <UserCheck />,
      title: "5. Ihre Rechte",
      content:
        "Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Widerspruch bezüglich Ihrer personenbezogenen Daten. Kontaktieren Sie uns dazu unter: kontakt@bottwarbytes.de",
    },
    {
      icon: <RefreshCw />,
      title: "6. Änderungen der Datenschutzerklärung",
      content:
        "Wir behalten uns vor, diese Datenschutzerklärung anzupassen. Die aktuelle Version finden Sie stets auf unserer Website. Bei wesentlichen Änderungen werden wir Sie informieren.",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Datenschutzerklärung
        </h1>
        <div className="grid md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow border border-gray-700"
            >
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-900 rounded-full mr-4">
                  {React.cloneElement(section.icon, {
                    className: "w-6 h-6 text-blue-400",
                  })}
                </div>
                <h2 className="text-xl font-semibold text-blue-300">
                  {section.title}
                </h2>
              </div>
              <p className="text-gray-300">{section.content}</p>
            </div>
          ))}
        </div>
        <div className="mt-12 bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            Kontakt für Datenschutzanfragen
          </h2>
          <p className="mb-2 text-gray-300">
            <strong className="text-blue-200">BottwarBytes GbR</strong>
          </p>
          <p className="mb-2 text-gray-300">
            Datenschutzbeauftragter: Jan Christian Swoboda
          </p>
          <p className="mb-2 text-gray-300">Seewiesenstr. 34</p>
          <p className="mb-2 text-gray-300">71723 Großbottwar</p>
          <p className="mb-2 text-gray-300">Deutschland</p>
          <p className="mb-2 text-gray-300">Telefon: +49 152 23132471</p>
          <p className="mb-2 text-gray-300">E-Mail: kontakt@bottwarbytes.de</p>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DatenschutzPage;
