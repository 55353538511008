import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight } from "lucide-react";
import NFTMetroImage from "../assets/NFT Metro.png";
import Amatwine from "../assets/amatwine.png";
import ShmettImage from "../assets/shmett.png";
import EternalContractImage from "../assets/ec.png";

const projects = [
  {
    name: "NFT Metro",
    image: NFTMetroImage,
    description:
      "Top-App im App Store: Für zwei Monate die #1 NFT-App Anfang 2021",
    details: [
      "Einfach erklärt: NFT Metro ermöglicht es Nutzern, digitale Sammlerstücke zu erstellen, zu kaufen und zu tauschen",
      "Benutzerfreundlich: Macht komplexe Blockchain-Technologie für jeden zugänglich",
      "Soziales Erlebnis: Teilen und Entdecken von Sammlungen mit Freunden",
    ],
  },
  {
    name: "Shmett",
    image: ShmettImage,
    description: "Positive Vibes für die deutsche Jugend: Shmett",
    details: [
      "Innovatives Konzept: Adaptierte Version der viralen US-App 'Gas' für den deutschen Markt",
      "Fokus auf Positivität: Ermöglicht Teenagern, sich gegenseitig anonyme Komplimente zu senden",
      "Einfache Bedienung: Intuitives Design für maximalen Spaß und Engagement",
    ],
  },
  {
    name: "Eternal Contract",
    image: EternalContractImage,
    description:
      "Revolutionäre Dokumentensicherheit: Eternal Contract schützt Ihre wichtigsten Daten",
    details: [
      "Innovative Desktop-Anwendung: Entwickelt mit Electron für macOS und Windows",
      "Höchste Sicherheitsstandards: Implementierung einer proprietären Verschlüsselungsmethode",
      "Benutzerfreundlich: Einfaches Speichern und Teilen von sensiblen Dokumenten",
    ],
  },
  {
    name: "Amatwine",
    image: Amatwine,
    description: "Luxus trifft Blockchain: Eine Revolution im Weinsammeln",
    details: [
      "Exklusive Kollektion: 100 einzigartige Weinflaschen, jede für 480€",
      "Partnerschaft: Zusammenarbeit mit dem Premium-Weinlabel 'amatwine'",
      "Blockchain-Innovation: Jede Flasche ist digital auf der Blockchain registriert",
    ],
  },
];

const ProjectCard = ({ project, isActive, onClick }) => (
  <motion.div
    className={`bg-gray-800 rounded-xl p-6 cursor-pointer transition-all duration-300 ${
      isActive ? "ring-2 ring-purple-500" : ""
    }`}
    onClick={onClick}
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
  >
    <img
      src={project.image}
      alt={project.name}
      className="w-full h-48 object-cover rounded-lg mb-4"
    />
    <h3 className="text-xl font-bold mb-2 text-white">{project.name}</h3>
    <p className="text-gray-400 text-sm">{project.description}</p>
    <ChevronRight
      className={`mt-4 text-purple-500 transition-transform duration-300 ${
        isActive ? "rotate-90" : ""
      }`}
    />
  </motion.div>
);

const ProjectDetails = ({ project }) => (
  <motion.div
    initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: "auto" }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
    className="bg-gray-800 rounded-xl p-6 mt-4 overflow-hidden"
  >
    <h3 className="text-2xl font-bold mb-4 text-white">{project.name}</h3>
    <ul className="space-y-2">
      {project.details.map((detail, index) => (
        <li key={index} className="flex items-start text-gray-300">
          <span className="text-purple-500 mr-2">•</span>
          <span>{detail}</span>
        </li>
      ))}
    </ul>
  </motion.div>
);

const ProjectSection = () => {
  const [activeProject, setActiveProject] = useState(null);

  return (
    <section className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-12 text-center">
          <span className="text-white">Unsere </span>
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Erfolgsgeschichten
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="flex flex-col">
              <ProjectCard
                project={project}
                isActive={activeProject === index}
                onClick={() =>
                  setActiveProject(activeProject === index ? null : index)
                }
              />
              <AnimatePresence>
                {activeProject === index && (
                  <ProjectDetails project={project} />
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
        <div className="mt-16 text-center">
          <p className="text-lg text-gray-300">
            Wir haben noch viele weitere spannende Projekte realisiert. Wenn Sie
            mehr erfahren möchten, sprechen Sie uns gerne an!
          </p>
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block mt-6 px-8 py-3 bg-gradient-to-r from-purple-500 to-pink-600 text-white font-semibold rounded-full hover:from-pink-600 hover:to-purple-500 transition-all duration-300 cursor-pointer text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Fragen Sie uns!
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
