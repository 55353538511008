import React from "react";
import { motion } from "framer-motion";
import {
  Linkedin,
  Code,
  Book,
  Briefcase,
  Coffee,
  MapPin,
  DollarSign,
  Cpu,
  ShieldCheck,
  ChevronDown,
} from "lucide-react";
import Navbar from "./Navbar";
import JanPic from "../assets/jan.jpeg";
import PhilippPic from "../assets/philipp.jpeg";

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const UberUnsPage = () => {
  return (
    <div className="min-h-screen bg-[#0F1624] text-white font-sans">
      <Navbar />

      <main className="pt-20 pb-12">
        <TeamSection />
        <OurStorySection />
        <WhyBottwarBytesSection />
      </main>

      <footer className="bg-[#080F1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

// ... (HeroSection remains unchanged)

const TeamSection = () => (
  <section className="py-20 bg-gradient-to-b from-[#1A2332] to-[#0F1624]">
    <div className="container mx-auto px-6">
      <motion.h2
        className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        variants={fadeInUpVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        Unser Team
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
        <TeamMember
          name="Jan Swoboda"
          role="Co-Founder & Rechtlicher Berater"
          image={JanPic}
          description={`
              Jan, 23, verbindet juristische Expertise mit technologischem Verständnis. 
              Sein Jurastudium ermöglicht ihm, komplexe rechtliche Aspekte in der Softwareentwicklung zu navigieren.
    
              • Jurastudent im fortgeschrittenen Stadium, Schwerpunkt Digitalisierung
              • Über 5 Jahre Programmiererfahrung
              • Vielseitige Interessen, die innovative Perspektiven in Projekte einbringen
              • Fokus auf die Schnittstelle zwischen Recht und Technologie
            `}
          linkedIn="https://www.linkedin.com/in/jan-swoboda/"
        />
        <TeamMember
          name="Philipp Storz"
          role="Co-Founder & Technischer Leiter"
          image={PhilippPic}
          description={`
              Philipp, 23, bringt eine einzigartige Kombination aus technischem Know-how und praktischer Erfahrung mit.
              Seine vielseitige Ausbildung ermöglicht ihm, komplexe technische Herausforderungen effektiv zu lösen.
    
              • Ausgebildeter Mechatroniker mit fundiertem Verständnis für Hardware und Software
              • Student des Software Engineering, spezialisiert auf moderne Entwicklungsmethoden
              • Erfahrener Handballer bei HABO, bringt Teamgeist und Führungsqualitäten mit
              • Praktische Erfahrung in der App-Entwicklung und Systemintegration
            `}
          linkedIn="https://www.linkedin.com/in/philipp-storz-a670a7201/"
        />
      </div>
    </div>
  </section>
);

const TeamMember = ({ name, role, image, description, linkedIn }) => (
  <motion.div
    className="bg-[#0F1624] rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 flex flex-col"
    variants={fadeInUpVariants}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
  >
    <div className="relative h-96 md:h-[450px] lg:h-[500px] overflow-hidden">
      <img
        src={image}
        alt={name}
        className="w-full h-90 object-cover object-center"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-[#0F1624] via-transparent to-transparent"></div>
    </div>
    <div className="p-8 flex-grow">
      <h3 className="text-3xl font-bold mb-2 text-white">{name}</h3>
      <p className="text-xl text-purple-400 mb-6">{role}</p>
      <div className="text-gray-300 mb-6 space-y-3">
        {description.split("\n").map((paragraph, index) => (
          <p key={index} className={index === 0 ? "font-semibold" : ""}>
            {paragraph.trim()}
          </p>
        ))}
      </div>
      <a
        href={linkedIn}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors text-lg"
      >
        <Linkedin className="mr-2" size={24} />
        LinkedIn Profil
      </a>
    </div>
  </motion.div>
);

const OurStorySection = () => {
  const milestones = [
    {
      year: 2006,
      event: "Jan und Philipp treffen sich in der ersten Klasse",
      icon: Book,
    },
    {
      year: 2011,
      event:
        "Erster 'Geschäftsplan': Ein Smartphone bauen (es blieb beim Plan)",
      icon: Code,
    },
    {
      year: 2017,
      event: "Leitung der Schülerfirma & erste Gehversuche im Unternehmertum",
      icon: Briefcase,
    },
    { year: 2020, event: "Erste App- und Webseiten-Entwicklung", icon: Coffee },
    { year: 2021, event: "Erste App mit über 9000 Downloads", icon: Code },
    { year: 2024, event: "Gründung von BottwarBytes", icon: Code },
  ];

  return (
    <section className="py-20 bg-[#0F1624]">
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          Unsere Geschichte
        </motion.h2>
        <div className="relative">
          {milestones.map((milestone, index) => (
            <motion.div
              key={index}
              className="flex flex-col md:flex-row items-center mb-12"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="w-full md:w-5/12 text-center md:text-right md:pr-8 mb-4 md:mb-0">
                <h3 className="text-2xl font-bold text-purple-400">
                  {milestone.year}
                </h3>
                <p className="text-gray-300 mt-2">{milestone.event}</p>
              </div>
              <div className="w-16 h-16 bg-gradient-to-br from-purple-500 to-pink-600 rounded-full flex items-center justify-center shadow-lg mb-4 md:mb-0">
                <milestone.icon size={28} className="text-white" />
              </div>
              <div className="w-full md:w-5/12 md:pl-8 hidden md:block"></div>
            </motion.div>
          ))}
          <div className="absolute top-0 bottom-0 left-1/2 w-1 bg-gradient-to-b from-purple-500 to-pink-600 -ml-0.5 hidden md:block"></div>
        </div>
      </div>
    </section>
  );
};

const WhyBottwarBytesSection = () => {
  const reasons = [
    {
      title: "Lokale Expertise",
      description:
        "Tiefes Verständnis für die spezifischen Bedürfnisse und Herausforderungen im Bottwartal und Umgebung",
      icon: MapPin,
    },
    {
      title: "Kosteneffiziente Lösungen",
      description:
        "Optimierte Preisstrukturen, die Qualität und Wirtschaftlichkeit in Einklang bringen",
      icon: DollarSign,
    },
    {
      title: "Technische Kompetenz",
      description:
        "Umfassendes Know-how von der Hardware-Integration bis zur komplexen Softwareentwicklung",
      icon: Cpu,
    },
    {
      title: "Rechtliche Sicherheit",
      description:
        "Fundierte juristische Kenntnisse garantieren rechtskonforme und sichere digitale Lösungen",
      icon: ShieldCheck,
    },
  ];

  return (
    <section className="py-20 bg-[#1A2332]">
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          Warum BottwarBytes?
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              className="bg-[#0F1624] rounded-lg p-6 shadow-lg hover:shadow-2xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                <div className="mr-4 bg-gradient-to-br from-purple-500 to-pink-600 rounded-full p-3 shadow-lg">
                  <reason.icon size={24} className="text-white" />
                </div>
                <h3 className="text-xl font-bold text-purple-300">
                  {reason.title}
                </h3>
              </div>
              <p className="text-gray-300">{reason.description}</p>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="mt-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-purple-500 to-pink-600 text-white font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Kontaktieren Sie uns
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default UberUnsPage;
