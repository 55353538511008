// UeberUnsSection.js
import React from "react";
import { motion } from "framer-motion";
import { Linkedin } from "lucide-react";
import {
  fadeInUpVariants,
  SectionTitle,
  sectionPadding,
  containerStyle,
} from "./sharedStyles";
import teamImage from "../assets/jp.JPEG";

const UeberUnsSection = () => {
  return (
    <section id="über-uns" className={`${sectionPadding} bg-gray-900`}>
      <div className={containerStyle}>
        <SectionTitle>Über uns</SectionTitle>
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div
            className="lg:w-1/2"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <img
              src={teamImage}
              alt="Jan und Philipp von BottwarBytes"
              className="w-full rounded-lg shadow-2xl"
            />
          </motion.div>
          <motion.div
            className="lg:w-1/2 space-y-6"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <p className="text-gray-300">
              <span className="font-semibold text-purple-400">Jan (links)</span>{" "}
              und{" "}
              <span className="font-semibold text-blue-400">
                Philipp (rechts)
              </span>{" "}
              - ein dynamisches Duo, das Technologie und Recht verbindet.
            </p>
            <p className="text-gray-300">
              Jan, unser Jurastudent mit Leidenschaft für Technologie, bringt
              eine einzigartige Perspektive in unsere digitalen Lösungen ein.
              Philipp, Informatikstudent und Handballer, verbindet Athletik mit
              technischem Fachwissen.
            </p>
            <p className="text-gray-300">
              Gemeinsam bilden wir ein Team, das innovative Lösungen entwickelt
              und dabei rechtliche sowie technische Aspekte perfekt in Einklang
              bringt.
            </p>
            <div className="flex space-x-4 pt-4">
              <a
                href="https://www.linkedin.com/in/jan-swoboda/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-purple-400 hover:text-purple-300 transition-colors"
              >
                <Linkedin className="mr-2" size={20} />
                Jan auf LinkedIn
              </a>
              <a
                href="https://www.linkedin.com/in/philipp-storz-a670a7201/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-blue-400 hover:text-blue-300 transition-colors"
              >
                <Linkedin className="mr-2" size={20} />
                Philipp auf LinkedIn
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default UeberUnsSection;
