import React from "react";
import { motion } from "framer-motion";
import {
  Smartphone,
  Globe,
  Cpu,
  Code,
  Lightbulb,
  HelpCircle,
  ExternalLink,
} from "lucide-react";
import Navbar from "./Navbar";

const GlassCard = ({ children, className }) => (
  <div
    className={`backdrop-blur-md bg-white bg-opacity-10 rounded-2xl p-6 border border-white border-opacity-20 shadow-xl ${className}`}
  >
    {children}
  </div>
);

const GradientButton = ({ children, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 text-white font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer text-center"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const ServiceCard = ({ icon: Icon, title, description, details }) => (
  <GlassCard className="h-full">
    <div className="flex items-center mb-4">
      <div className="mr-4 p-3 bg-gradient-to-br from-purple-500 to-blue-500 rounded-2xl">
        <Icon size={24} className="text-white" />
      </div>
      <h3 className="text-xl font-bold text-white">{title}</h3>
    </div>
    <p className="text-gray-300 mb-4">{description}</p>
    <ul className="list-disc list-inside text-gray-400">
      {details.map((detail, index) => (
        <li key={index} className="mb-2">
          {typeof detail === "string" ? (
            detail
          ) : (
            <React.Fragment>
              {detail.text}
              {detail.link && (
                <a
                  href={detail.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 ml-1 inline-flex items-center"
                >
                  Artikel lesen <ExternalLink size={14} className="ml-1" />
                </a>
              )}
            </React.Fragment>
          )}
        </li>
      ))}
    </ul>
  </GlassCard>
);

const LeistungenPage = () => {
  return (
    <div className="min-h-screen bg-[#0F1624] text-white flex flex-col">
      <Navbar />
      <main className="flex-grow py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500">
            Unsere Leistungen: Digitale Exzellenz
          </h1>

          <p className="text-gray-300 text-xl mb-12 text-center max-w-3xl mx-auto">
            Maßgeschneiderte digitale Lösungen, entwickelt mit modernsten
            Technologien und echtem Programmier-Know-how. Keine Templates, nur
            individuelle Anwendungen für Ihre Bedürfnisse.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <ServiceCard
              icon={Smartphone}
              title="Mobile App-Entwicklung"
              description="Native und plattformübergreifende Apps, die Ihre Nutzer begeistern."
              details={[
                "iOS-Entwicklung mit Swift",
                "Plattformübergreifende Apps mit React Native",
                "Benutzerfreundliches UI/UX-Design",
                "Integration von Backend-Systemen und APIs",
              ]}
            />
            <ServiceCard
              icon={Globe}
              title="Webentwicklung"
              description="Moderne, schnelle und skalierbare Webanwendungen."
              details={[
                "Frontend-Entwicklung mit React.js",
                "Server-Side Rendering mit Next.js für optimale Performance",
                "Vollständig anpassbare Lösungen ohne Einschränkungen",
                "Responsive Design für alle Geräte",
              ]}
            />
            <ServiceCard
              icon={Cpu}
              title="KI-Integration & Beratung"
              description="Nutzen Sie das Potenzial der künstlichen Intelligenz für Ihr Unternehmen."
              details={[
                "Integration von KI-Lösungen in bestehende Systeme",
                "Entwicklung individueller KI-Anwendungen",
                "Beratung zur Implementierung von KI in Geschäftsprozessen",
                {
                  text: "Erfahrung: Artikel in Stuttgarter Nachrichten über KI-Nutzung",
                  link: "https://www.stuttgarter-nachrichten.de/inhalt.chatgpt-fuer-die-region-ludwigsburg-kuenstliche-intelligenz-in-lokalen-unternehmen-nutzen.e6b0e92f-c356-446e-b07a-561d89a189de.html",
                },
              ]}
            />
            <ServiceCard
              icon={Code}
              title="Custom Software-Entwicklung"
              description="Maßgeschneiderte Softwarelösungen für Ihre spezifischen Anforderungen."
              details={[
                "Entwicklung von Grund auf mit echten Programmiersprachen",
                "Keine Einschränkungen durch vorgefertigte Systeme wie WordPress",
                "Höchste Qualität und volle Anpassbarkeit",
                "Skalierbare Architekturen für zukünftiges Wachstum",
              ]}
            />
          </div>

          <GlassCard className="mb-16">
            <h3 className="text-2xl font-bold mb-4 text-white flex items-center">
              <Lightbulb className="mr-2 text-yellow-400" size={28} />
              Warum wir anders sind
            </h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Echte Programmierkenntnisse statt vorgefertigter Lösungen</li>
              <li>
                Umfassendes Know-how in digitalen Technologien, einschließlich
                Blockchain
              </li>
              <li>Fokus auf nachhaltige und sinnvolle Technologienutzung</li>
              <li>
                Transparente Beratung: Wir empfehlen nur, was wirklich Mehrwert
                bringt
              </li>
              <li>
                Kontinuierliche Weiterbildung, um immer auf dem neuesten Stand
                zu bleiben
              </li>
            </ul>
          </GlassCard>

          <div className="text-center">
            <h3 className="text-3xl font-bold mb-4 text-white flex items-center justify-center">
              <HelpCircle className="mr-2 text-blue-400" size={28} />
              Noch Fragen?
            </h3>
            <p className="text-gray-300 text-lg mb-8 max-w-2xl mx-auto">
              Ob Sie konkrete Projektideen haben oder einfach nur ein paar
              Gedanken austauschen möchten - wir sind für Sie da. Für
              grundlegende Fragen oder einen kurzen Austausch stehen wir Ihnen
              gerne kostenlos zur Verfügung.
            </p>
            <GradientButton href="https://calendly.com/jan_swoboda/30min">
              Jetzt Gespräch vereinbaren
            </GradientButton>
          </div>
        </div>
      </main>

      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LeistungenPage;
