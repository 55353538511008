import React from "react";
import { motion } from "framer-motion";
import { Zap, Clock, CheckCircle, Settings, Phone } from "lucide-react";

const PricingProcessSection = () => {
  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section className="py-24 bg-gray-900 text-gray-100">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-4xl font-bold mb-16 text-center"
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <span className="bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
            Unser Ansatz: Effizient, Fair, Transparent
          </span>
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <motion.div
            className="bg-gray-800 rounded-2xl p-8 shadow-lg border border-gray-700"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h3 className="text-2xl font-semibold mb-6 flex items-center">
              <Zap className="mr-3 text-yellow-400" />
              Schnell & Fokussiert
            </h3>
            <p className="text-gray-400 mb-6">
              Wir sind die Alternative zu großen, trägen Softwarehäusern. Unser
              Fokus liegt auf Geschwindigkeit und Effizienz, ohne Kompromisse
              bei der Qualität einzugehen.
            </p>
            <ul className="space-y-4">
              {[
                "Schnelle Projektrealisierung",
                "Direkter Kontakt zum Entwicklerteam",
                "Flexibilität bei Änderungen",
              ].map((item, index) => (
                <li key={index} className="flex items-center">
                  <CheckCircle className="mr-3 text-green-400" size={20} />
                  <span>{item}</span>
                </li>
              ))}
              <li className="flex items-center">
                <Phone className="mr-3 text-green-400" size={20} />
                <span>
                  Direkte Erreichbarkeit:{" "}
                  <a
                    href="tel:+4915223132471"
                    className="text-blue-400 hover:underline"
                  >
                    +49 152 2313 2471
                  </a>
                </span>
              </li>
            </ul>
          </motion.div>

          <motion.div
            className="bg-gray-800 rounded-2xl p-8 shadow-lg border border-gray-700"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h3 className="text-2xl font-semibold mb-6 flex items-center">
              <Clock className="mr-3 text-blue-400" />
              Unser Prozess
            </h3>
            <ol className="space-y-6">
              {[
                {
                  title: "Projektbesprechung",
                  description: "Wir diskutieren Ihre Anforderungen im Detail.",
                },
                {
                  title: "Festpreis-Vereinbarung",
                  description:
                    "Basierend auf dem Umfang einigen wir uns auf einen festen Preis.",
                },
                {
                  title: "Entwicklung & Lieferung",
                  description:
                    "Wir realisieren das Projekt und liefern es termingerecht.",
                },
                {
                  title: "Feinschliff",
                  description:
                    "Sie haben Anspruch auf bis zu 5 Anpassungen nach Lieferung.",
                },
              ].map((step, index) => (
                <li key={index} className="flex items-start">
                  <span className="bg-blue-500 rounded-full flex-shrink-0 w-8 h-8 flex items-center justify-center mr-4 mt-1">
                    <span className="text-sm font-bold">{index + 1}</span>
                  </span>
                  <div>
                    <strong className="block mb-1 text-lg">{step.title}</strong>
                    <p className="text-gray-400">{step.description}</p>
                  </div>
                </li>
              ))}
            </ol>
          </motion.div>
        </div>

        <motion.div
          className="text-center bg-gray-800 rounded-2xl p-8 shadow-lg border border-gray-700"
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h3 className="text-2xl font-semibold mb-6 flex items-center justify-center">
            <Settings className="mr-3 text-purple-400" />
            Wartung & Support
          </h3>
          <p className="text-gray-400 mb-8">
            Wir bieten optionale Wartung zu einem festen monatlichen Preis.
            Kritische Fehler beheben wir selbstverständlich kostenlos.
          </p>
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-4 px-8 rounded-full text-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Kostenloses Beratungsgespräch vereinbaren
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default PricingProcessSection;
