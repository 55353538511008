import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { Smartphone, Globe, Code } from "lucide-react";

const HeroSection = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start("visible");
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <section
      ref={sectionRef}
      className="min-h-screen flex items-center justify-center bg-gray-900 overflow-hidden relative px-4 py-16"
    >
      <div className="max-w-6xl mx-auto z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className="text-center"
        >
          <motion.h1
            variants={itemVariants}
            className="text-5xl md:text-7xl font-extrabold mb-6 text-white"
          >
            Digitale Lösungen
            <span className="block text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-500">
              aus dem Ländle
            </span>
          </motion.h1>
          <motion.h2
            variants={itemVariants}
            className="text-3xl md:text-5xl font-bold mb-8 text-gray-300"
          >
            Wir bringen Ihre digitalen Ideen zum Leben
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-xl md:text-2xl mb-12 text-gray-400"
          >
            Schwäbischer Erfindergeist trifft auf moderne Technologie
          </motion.p>
          <motion.div
            variants={containerVariants}
            className="flex flex-wrap justify-center gap-8 mb-16"
          >
            {[
              { icon: Smartphone, text: "Apps" },
              { icon: Globe, text: "Webseiten" },
              { icon: Code, text: "Software" },
            ].map((item, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.05, y: -5 }}
                className="text-center bg-gray-800 p-6 rounded-lg shadow-lg"
              >
                <item.icon size={48} className="text-cyan-400 mx-auto mb-4" />
                <p className="text-white text-lg font-semibold">{item.text}</p>
              </motion.div>
            ))}
          </motion.div>
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-block bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold py-4 px-10 rounded-lg text-xl shadow-lg hover:shadow-cyan-500/50 transition duration-300 cursor-pointer"
          >
            Projekt starten
          </motion.a>
        </motion.div>
      </div>
      <div className="absolute inset-0 bg-[radial-gradient(circle,_rgba(255,255,255,0.1)_1px,_transparent_1px)] bg-[length:20px_20px]"></div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 0.15, 0] }}
        transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
        className="absolute inset-0 bg-gradient-to-br from-cyan-500/20 to-blue-600/20 blur-3xl"
      ></motion.div>
    </section>
  );
};

export default HeroSection;
