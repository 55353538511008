import React from "react";
import { motion } from "framer-motion";
import { Smartphone, Globe, Cpu, Zap } from "lucide-react";

const GlassCard = ({ children, className }) => (
  <motion.div
    className={`backdrop-blur-md bg-white bg-opacity-10 rounded-2xl p-6 border border-white border-opacity-20 shadow-xl overflow-hidden ${className}`}
    whileHover={{ scale: 1.05, boxShadow: "0 0 30px rgba(255,255,255,0.2)" }}
  >
    {children}
  </motion.div>
);

const ServiceCard = ({ icon: Icon, title, description, gradient }) => (
  <GlassCard>
    <div
      className={`absolute -right-10 -top-10 w-40 h-40 rounded-full opacity-20 ${gradient}`}
    />
    <Icon size={48} className="mb-4 text-white" />
    <h3 className="text-xl font-bold mb-2 text-white">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </GlassCard>
);

const LeistungenSection = () => {
  return (
    <section className="py-20 bg-[#0F1624] text-white overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-4xl md:text-5xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Unsere Leistungen: Digitale Meisterwerke
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <ServiceCard
            icon={Smartphone}
            title="Mobile App-Entwicklung"
            description="Wir erschaffen intuitive und leistungsstarke Apps, die Ihre Nutzer begeistern. Von der Konzeption bis zum App Store – wir begleiten Sie durch den gesamten Prozess."
            gradient="bg-gradient-to-br from-blue-600 to-blue-800"
          />
          <ServiceCard
            icon={Globe}
            title="Webentwicklung"
            description="Responsive Websites und Web-Apps, die nicht nur gut aussehen, sondern auch performant sind. Wir nutzen modernste Technologien für ein optimales Nutzererlebnis."
            gradient="bg-gradient-to-br from-purple-600 to-purple-800"
          />
          <ServiceCard
            icon={Cpu}
            title="KI-Integration"
            description="Nutzen Sie die Kraft der künstlichen Intelligenz. Wir integrieren KI-Lösungen in Ihre bestehenden Systeme oder entwickeln komplett neue, intelligente Anwendungen."
            gradient="bg-gradient-to-br from-green-600 to-green-800"
          />
          <ServiceCard
            icon={Zap}
            title="Digitale Transformation"
            description="Wir begleiten Sie auf dem Weg in die digitale Zukunft. Von der Prozessoptimierung bis zur Implementierung neuer Technologien – wir machen Ihr Unternehmen fit für das digitale Zeitalter."
            gradient="bg-gradient-to-br from-yellow-600 to-yellow-800"
          />
        </div>

        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <h3 className="text-2xl font-bold mb-4 text-white">
            Bereit für den nächsten Schritt?
          </h3>
          <p className="text-gray-300 mb-8">
            Lassen Sie uns gemeinsam Ihre digitale Vision verwirklichen.
          </p>
          <motion.a
            href="https://calendly.com/jan_swoboda/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-purple-500 to-pink-600 text-white font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer text-center"
            whileHover={{
              scale: 1.05,
              boxShadow: "0 0 25px rgba(255,255,255,0.3)",
            }}
            whileTap={{ scale: 0.95 }}
          >
            Kontaktieren Sie uns
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default LeistungenSection;
