// sharedStyles.js
import { motion } from "framer-motion";

export const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

export const gradientText =
  "text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500";

export const SectionTitle = ({ children }) => (
  <motion.h2
    className={`text-4xl font-bold mb-12 text-center ${gradientText}`}
    variants={fadeInUpVariants}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
  >
    {children}
  </motion.h2>
);

export const sectionPadding = "py-20";
export const containerStyle = "container mx-auto px-6";
