import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import AGBPage from "./components/AGBPage";
import DatenschutzPage from "./components/DatenschutzPage";
import UberUnsPage from "./components/UberUnsPage";
import PricingPage from "./components/PricingPage";
import LeistungenPage from "./components/LeistungenPage";
import WAPrivacy from "./components/WAPrivacy";
import WATos from "./components/WATos";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/agb" element={<AGBPage />} />
        <Route path="/datenschutz" element={<DatenschutzPage />} />
        <Route path="/uber-uns" element={<UberUnsPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/leistungen" element={<LeistungenPage />} />
        <Route path="/WAPrivacy" element={<WAPrivacy />} />
        <Route path="/WATerms" element={<WATos />} />
      </Routes>
    </Router>
  );
};

export default App;
