import React from "react";
import { Link } from "react-router-dom";
import {
  Scroll,
  Shield,
  UserCheck,
  Zap,
  AlertTriangle,
  Mail,
  Info,
} from "lucide-react";
import Navbar from "./Navbar";

const AGBPage = () => {
  const sections = [
    {
      icon: <Scroll />,
      title: "1. Annahme der Bedingungen",
      content:
        "Durch die Nutzung unserer Dienste erklärst du dich damit einverstanden, dich an unsere AGBs zu halten.",
    },
    {
      icon: <Zap />,
      title: "2. Unsere Dienstleistungen",
      content:
        "Wir entwickeln mobile Apps, Websites und Desktop-Anwendungen mit höchster Qualität und Professionalität.",
    },
    {
      icon: <UserCheck />,
      title: "3. Nutzungsbedingungen",
      content:
        "Nutze unsere Dienste bitte ausschließlich für legale und angemessene Zwecke im Einklang mit unseren Richtlinien.",
    },
    {
      icon: <Shield />,
      title: "4. Geistiges Eigentum",
      content:
        "Alle von uns erstellten Inhalte und Codes sind unser geistiges Eigentum. Lizenzbedingungen werden individuell vereinbart.",
    },
    {
      icon: <AlertTriangle />,
      title: "5. Haftungsbeschränkung",
      content:
        "Wir streben stets nach Perfektion, können jedoch nicht für jedes unvorhergesehene digitale Problem haften. Bitte nutzen Sie unsere Dienste mit Sorgfalt.",
    },
    {
      icon: <Mail />,
      title: "6. Kontakt",
      content:
        "Für Fragen oder Anregungen erreichen Sie uns unter: kontakt@bottwarbytes.de",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Allgemeine Geschäftsbedingungen
        </h1>
        <div className="grid md:grid-cols-2 gap-8">
          {sections.map((section, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow border border-gray-700"
            >
              <div className="flex items-center mb-4">
                <div className="p-2 bg-blue-900 rounded-full mr-4">
                  {React.cloneElement(section.icon, {
                    className: "w-6 h-6 text-blue-400",
                  })}
                </div>
                <h2 className="text-xl font-semibold text-blue-300">
                  {section.title}
                </h2>
              </div>
              <p className="text-gray-300">{section.content}</p>
            </div>
          ))}
        </div>
        <div className="mt-12 bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            <Info className="mr-2 text-blue-400" />
            Impressum
          </h2>
          <p className="mb-2 text-gray-300">
            <strong className="text-blue-200">BottwarBytes GbR</strong>
          </p>
          <p className="mb-2 text-gray-300">
            Vertreten durch: Jan Christian Swoboda
          </p>
          <p className="mb-2 text-gray-300">Seewiesenstr. 34</p>
          <p className="mb-2 text-gray-300">71723 Großbottwar</p>
          <p className="mb-2 text-gray-300">Deutschland</p>
          <p className="mb-2 text-gray-300">Telefon: +49 152 23132471</p>
          <p className="mb-2 text-gray-300">E-Mail: kontakt@bottwarbytes.de</p>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AGBPage;
