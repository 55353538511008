import React, { useState } from "react";
import { motion } from "framer-motion";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import {
  fadeInUpVariants,
  gradientText,
  sectionPadding,
  containerStyle,
} from "./sharedStyles";

const firebaseConfig = {
  apiKey: "AIzaSyBhE6m-VX-Hq5MApuqn-XRqH_tqXQFFppc",
  authDomain: "bottwarbytes.firebaseapp.com",
  projectId: "bottwarbytes",
  storageBucket: "bottwarbytes.appspot.com",
  messagingSenderId: "348176037737",
  appId: "1:348176037737:web:83717208189e4404102ff8",
  measurementId: "G-R6SV3WV7S9",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message) {
      return false;
    }
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(formData.email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setFormStatus({
        type: "error",
        message: "Bitte füllen Sie alle Felder korrekt aus.",
      });
      return;
    }

    setFormStatus({ type: "loading", message: "Nachricht wird gesendet..." });

    try {
      const docRef = await addDoc(collection(db, "contacts"), formData);
      console.log("Document written with ID: ", docRef.id);

      setFormStatus({
        type: "success",
        message: "Ihre Nachricht wurde erfolgreich gespeichert!",
      });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error adding document: ", error);
      setFormStatus({
        type: "error",
        message: `Es gab ein Problem beim Speichern Ihrer Nachricht: ${error.message}. Bitte versuchen Sie es später erneut.`,
      });
    }
  };

  return (
    <section id="kontakt" className={sectionPadding}>
      <div className={containerStyle}>
        <motion.h2
          className={`text-4xl font-bold mb-12 text-center ${gradientText}`}
          variants={fadeInUpVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          Kontaktieren Sie uns
        </motion.h2>
        <div className="max-w-md mx-auto">
          <motion.p
            className="text-center mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Bereit, Ihre Ideen in die digitale Realität umzusetzen? Nehmen Sie
            Kontakt mit uns auf.
          </motion.p>
          <motion.form
            className="space-y-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
              className="w-full p-3 bg-[#2A2A2A] rounded-lg focus:ring-2 focus:ring-purple-500"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="E-Mail"
              className="w-full p-3 bg-[#2A2A2A] rounded-lg focus:ring-2 focus:ring-purple-500"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Nachricht"
              rows="4"
              className="w-full p-3 bg-[#2A2A2A] rounded-lg focus:ring-2 focus:ring-purple-500"
              required
            ></textarea>
            <motion.button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg font-semibold hover:from-purple-600 hover:to-blue-500 transition-all duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={formStatus && formStatus.type === "loading"}
            >
              {formStatus && formStatus.type === "loading"
                ? "Wird gesendet..."
                : "Nachricht senden"}
            </motion.button>
          </motion.form>
          {formStatus && (
            <motion.div
              className={`mt-4 p-3 rounded-lg ${
                formStatus.type === "success"
                  ? "bg-green-500"
                  : formStatus.type === "error"
                  ? "bg-red-500"
                  : "bg-blue-500"
              }`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {formStatus.message}
            </motion.div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
