import React from "react";
import { Info } from "lucide-react";
import Navbar from "./Navbar";

const WATos = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100">
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-400">
          Terms of Service
        </h1>
        <div className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-300">
            <Info className="mr-2 text-blue-400" />
            WhatsAnalyze Terms of Service
          </h2>
          <div className="text-gray-300 whitespace-pre-wrap">
            <p>
              <strong>Last updated: September 25, 2024</strong>
            </p>
            <p>
              Welcome to WhatsAnalyze ("we," "our," or "us"). These Terms of
              Service ("Terms") govern your use of our app and services. By
              accessing or using our app, you agree to comply with these Terms.
              Please read them carefully.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              1. Acceptance of Terms
            </h3>
            <p>
              By using WhatsAnalyze, you accept and agree to be bound by these
              Terms. If you do not agree with any part of these Terms, please
              refrain from using our app.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              2. Eligibility
            </h3>
            <p>
              You must be at least 18 years old or have the legal capacity to
              enter into agreements to use our services. By using WhatsAnalyze,
              you represent and warrant that you meet these eligibility
              requirements.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              3. Use of the App
            </h3>
            <p>
              WhatsAnalyze provides a service that allows you to analyze your
              WhatsApp chats. You agree to use the app in a manner consistent
              with all applicable laws and regulations. You are responsible for
              maintaining the security of your login credentials and for all
              activities that occur under your account.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              4. User Content and Data
            </h3>
            <p>
              When using our app, you agree that:
              <br />
              - Your WhatsApp chats are stored locally on your device.
              <br />
              - We do not store your chat data on our servers, but we store the
              analyzed responses.
              <br />
              - You are responsible for the legality of the data you provide for
              analysis.
              <br />- Claude from Anthropic will process your chat data for
              analysis.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              5. Intellectual Property
            </h3>
            <p>
              All intellectual property rights related to WhatsAnalyze,
              including but not limited to the app's design, features, and
              functionality, are owned by us. You are granted a limited,
              non-exclusive, non-transferable, and revocable license to use the
              app for personal, non-commercial purposes.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              6. Prohibited Activities
            </h3>
            <p>
              You agree not to:
              <br />
              - Use the app for illegal purposes.
              <br />
              - Copy, modify, or distribute any part of the app without our
              permission.
              <br />- Engage in any activity that interferes with or disrupts
              the app's functionality.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              7. Limitation of Liability
            </h3>
            <p>
              We strive to provide a high-quality service, but we cannot
              guarantee that our app will always function without errors. We
              disclaim all liability for any damages resulting from your use of
              the app, including but not limited to data loss, errors, or
              interruptions.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              8. Changes to the Terms
            </h3>
            <p>
              We may update these Terms from time to time. Any changes will be
              posted within the app, and we encourage you to review the Terms
              periodically. Your continued use of the app after any changes
              signifies your acceptance of the revised Terms.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              9. Termination
            </h3>
            <p>
              We reserve the right to terminate or suspend your access to the
              app at any time, without notice, for conduct that we believe
              violates these Terms or is harmful to other users or us.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              10. Governing Law
            </h3>
            <p>
              These Terms are governed by the laws of Germany, and any disputes
              arising from them will be resolved in the courts of Wiesbaden.
            </p>

            <h3 className="text-xl font-semibold mt-4 text-blue-300">
              11. Contact Information
            </h3>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at:
              <br />- <strong>Email:</strong> kontakt@bottwarbytes.de
              <br />- <strong>Address:</strong> Dotzheimer str. 152, 65197
              Wiesbaden
            </p>
          </div>
        </div>
      </main>
      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. All rights reserved.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WATos;
