import React from "react";
import { motion } from "framer-motion";
import {
  Zap,
  Clock,
  CheckCircle,
  DollarSign,
  Smile,
  FastForward,
  Shield,
  Target,
} from "lucide-react";
import Navbar from "./Navbar";
import CostComparisonSection from "./CostComparisonSection";

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans">
      <Navbar />

      <main className="pt-16 sm:pt-20 md:pt-24 pb-12">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h1
            className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-4 sm:mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
          >
            Festpreis-Modell: Die Zukunft der Softwareentwicklung
          </motion.h1>

          <motion.p
            className="text-base sm:text-lg md:text-xl text-center text-gray-300 mb-8 sm:mb-12 max-w-3xl mx-auto"
            variants={fadeInUpVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.2 }}
          >
            Transparenz, Effizienz und Qualität – Unser Versprechen an Sie
          </motion.p>

          <motion.section
            className="mb-12 sm:mb-16 md:mb-20"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-6 sm:mb-8 md:mb-10 text-center">
              Warum unser Festpreis-Modell überzeugt
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8">
              {[
                {
                  icon: DollarSign,
                  title: "Kostentransparenz",
                  description: "Keine versteckten Kosten oder Überraschungen",
                },
                {
                  icon: FastForward,
                  title: "Effizienz",
                  description: "Schnellere Entwicklung, schnellere Ergebnisse",
                },
                {
                  icon: Shield,
                  title: "Risikominimierung",
                  description: "Wir tragen das Risiko von Verzögerungen",
                },
                {
                  icon: Smile,
                  title: "Zufriedenheitsgarantie",
                  description: "Inklusive Anpassungen nach Lieferung",
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-800 rounded-xl p-4 sm:p-6 shadow-lg hover:shadow-2xl transition-all duration-300"
                  whileHover={{ y: -5 }}
                >
                  <item.icon className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 mb-3 sm:mb-4 text-purple-400" />
                  <h3 className="text-base sm:text-lg md:text-xl font-semibold mb-2">
                    {item.title}
                  </h3>
                  <p className="text-xs sm:text-sm md:text-base text-gray-300">
                    {item.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </motion.section>

          <CostComparisonSection />

          <motion.section
            className="my-16 sm:my-20 md:my-32"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-8 sm:mb-10 md:mb-16 text-center">
              Unser Prozess: Vom Konzept zur Umsetzung
            </h2>
            <div className="relative max-w-5xl mx-auto">
              {[
                {
                  icon: Target,
                  title: "Bedarfsanalyse",
                  description: "Wir verstehen Ihre Anforderungen im Detail",
                },
                {
                  icon: DollarSign,
                  title: "Festpreisangebot",
                  description: "Transparent und ohne versteckte Kosten",
                },
                {
                  icon: Zap,
                  title: "Effiziente Entwicklung",
                  description: "Schnelle Umsetzung durch fokussiertes Arbeiten",
                },
                {
                  icon: CheckCircle,
                  title: "Lieferung & Feinschliff",
                  description: "Inklusive Anpassungen nach Ihren Wünschen",
                },
              ].map((step, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col md:flex-row items-center mb-8 sm:mb-12 md:mb-24 last:mb-0"
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className="w-full md:w-5/12 text-center md:text-right md:pr-8 mb-4 md:mb-0 order-2 md:order-1">
                    <h3 className="text-base sm:text-lg md:text-xl font-bold">
                      {step.title}
                    </h3>
                    <p className="text-xs sm:text-sm md:text-base text-gray-300 mt-2">
                      {step.description}
                    </p>
                  </div>
                  <div className="w-full md:w-2/12 flex justify-center mb-4 md:mb-0 order-1 md:order-2">
                    <div className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 bg-purple-500 rounded-full flex items-center justify-center z-10 shadow-lg">
                      <step.icon size={20} className="text-white" />
                    </div>
                  </div>
                  <div className="w-full md:w-5/12 order-3"></div>
                </motion.div>
              ))}
              <div className="absolute top-0 bottom-0 left-1/2 w-0.5 bg-purple-500 -ml-0.5 hidden md:block"></div>
            </div>
          </motion.section>

          <motion.section
            className="text-center p-6 sm:p-8 md:p-12 lg:p-16 bg-gray-800 rounded-2xl shadow-2xl"
            variants={fadeInUpVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 sm:mb-6 lg:mb-8 text-white">
              Bereit für den nächsten Schritt?
            </h2>
            <p className="text-base sm:text-lg lg:text-xl text-gray-300 mb-6 sm:mb-8 lg:mb-10 max-w-2xl mx-auto">
              Lassen Sie uns gemeinsam Ihr Projekt besprechen und herausfinden,
              wie wir Ihre Vision in die Realität umsetzen können.
            </p>
            <motion.a
              href="https://calendly.com/jan_swoboda/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 px-6 sm:px-8 rounded-full text-sm sm:text-base lg:text-lg shadow-lg hover:shadow-xl transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Kostenloses Beratungsgespräch vereinbaren
            </motion.a>
          </motion.section>
        </div>
      </main>

      <footer className="bg-[#1C1C1E] text-gray-400 py-6 sm:py-8">
        <div className="container mx-auto px-6 text-center">
          <p className="text-sm sm:text-base">
            &copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.
          </p>
          <div className="mt-3 sm:mt-4">
            <a
              href="/agb"
              className="text-sm sm:text-base hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="text-sm sm:text-base hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PricingPage;
