import React, { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
import { Calendar, Menu, X } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollY } = useViewportScroll();
  const backgroundColor = useTransform(
    scrollY,
    [0, 50],
    ["rgba(15, 22, 36, 0)", "rgba(15, 22, 36, 0.9)"]
  );

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Über uns", path: "/uber-uns" },
    { name: "Preismodell", path: "/pricing" },
    { name: "Leistungen", path: "/leistungen" },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CTAButton = ({ className = "" }) => (
    <motion.a
      href="https://calendly.com/jan_swoboda/30min"
      target="_blank"
      rel="noopener noreferrer"
      className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-pink-600 rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Calendar className="w-4 h-4 mr-2" />
      Termin vereinbaren
    </motion.a>
  );

  return (
    <motion.nav
      style={{ backgroundColor }}
      className="fixed w-full z-50 transition-all duration-300"
    >
      <div className="container mx-auto px-4 py-2 md:py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-xl md:text-2xl font-bold text-white">
            BottwarBytes
          </Link>
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className="text-white hover:text-gray-300 transition-colors"
              >
                {item.name}
              </Link>
            ))}
            <CTAButton />
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white focus:outline-none ml-4"
              aria-label="Toggle menu"
            >
              {isOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="md:hidden bg-[#0F1624] bg-opacity-95 py-2"
        >
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className="block py-2 px-4 text-white hover:bg-gray-800 transition-colors"
              onClick={() => setIsOpen(false)}
            >
              {item.name}
            </Link>
          ))}
          <div className="px-4 py-2">
            <CTAButton className="w-full justify-center" />
          </div>
        </motion.div>
      )}
    </motion.nav>
  );
};

export default Navbar;
