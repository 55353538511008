import React, { useState } from "react";
import UeberUnsSection from "./UeberUnsSection";
import HeroSection from "./HeroSection";
import Navbar from "./Navbar";
import LeistungenSection from "./LeistungenSection";
import PricingProcessSection from "./PricingProcessSection";
import ContactSection from "./ContactSection";
import ProjectSection from "./ProjectSection";

const HomePage = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <Navbar />
      <main>
        <HeroSection />
        <UeberUnsSection />
        <ProjectSection />
        <PricingProcessSection />
        <LeistungenSection />
        <ContactSection />
      </main>

      <footer className="bg-[#1C1C1E] text-gray-400 py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 BottwarBytes GbR. Alle Rechte vorbehalten.</p>
          <div className="mt-4">
            <a
              href="/agb"
              className="hover:text-purple-400 mr-4 transition-colors"
            >
              AGB
            </a>
            <a
              href="/datenschutz"
              className="hover:text-purple-400 transition-colors"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
